<template>
  <div>
    <b-breadcrumb class="breadcrumb-slash mb-1">
      <b-breadcrumb-item :to="{ name: 'dashboardadmin-ecommerce' }">
        จัดการสินค้า
        <!-- Dashboard -->
      </b-breadcrumb-item>
      <b-breadcrumb-item active> รายการสินค้า </b-breadcrumb-item>
    </b-breadcrumb>

    <!-- Filters -->
    <!-- <product-list-filters
      :product-name-filter.sync="productNameFilter"
      :product-category-filter="productCategoryFilter"
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label> แสดง </label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label> รายการ </label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="ค้นหา"
              />
              <!-- <b-buttons
                variant="primary"
                :to="{ name: 'admin-products-edit', params: {id: 35} }"
              >
                <span class="text-nowrap">
                  แก้ไข
                </span>
              </b-button> -->
              <b-button
                variant="primary"
                :to="{ name: 'admin-products-create' }"
              >
                <span class="text-nowrap"> + เพิ่มสินค้าใหม่ </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refProductListTable"
        class="position-relative"
        :items="fetchProducts"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="'ไม่พบข้อมูล'"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ (currentPage - 1) * perPage + data.index + 1 }}
        </template>
        <template #cell(productId)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.product_Image_Id.url"
                :text="avatarText(data.item.productName)"
                :to="{
                  name: 'admin-products-edit',
                  params: { id: data.item.productId },
                }"
              />
            </template>

            {{ data.item.productCode }}
          </b-media>
        </template>

        <template #cell(productName)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{
                name: 'admin-products-edit',
                params: { id: data.item.productId },
              }"
              class="font-weight-bold d-block"
            >
              {{ data.item.productName }}
            </b-link>
          </b-media>
        </template>

        <template #cell(moreOptions)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.moreOptions)}`"
            class="text-capitalize"
          >
            {{ data.item.moreOptions }}
          </b-badge>
        </template>

        <template #cell(alert_day)="data">
            {{ data.item.alert_day > 0 ? data.item.alert_day: 'ยังไม่ได้ตั้งค่า'}}
        </template>

        <template #cell(status)="data">
          <b-form-checkbox
            checked="true"
            name="check-button"
            switch
            inline
            v-model="data.item.active"
            disabled
          >
          </b-form-checkbox>
        </template>

        <template #cell(price)="data">
          <span>{{ data.item.price.toLocaleString("en-US") }} </span>
        </template>

        <!--  <template #cell(sales)="data">
          <span>{{ data.item.sales.toLocaleString("en-US") }} </span>
        </template> -->

        <template #cell(productCategory)="data">
          <span>{{ data.item.productCategory.categoryName }} </span>
        </template>
        <template #cell(productColor)="data">
          <input
            type="color"
            value="#ff0000"
            style="
              height: 3rem;
              min-width: 50%;
              border: none;
              background: transparent;
            "
            v-model="data.item.productColor"
            disabled
          />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{
                name: 'admin-products-edit',
                params: { id: data.item.productId },
              }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50"> Edit </span>
            </b-dropdown-item>

            <b-dropdown-item @click="handlerDeleteProduct(data.item.productId)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50"> Delete </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              แสดง
              {{ dataMeta.to }}
              จาก
              {{ dataMeta.of }}
              รายการ
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProducts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBreadcrumb,
  BBreadcrumbItem,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
// eslint-disable-next-line import/no-cycle
import { avatarText } from "@core/utils/filter";
// import ProductListFilters from './ProductListFilters.vue'
import useProductList from "./useProductList";
import productStoreModule from "./productStoreModule";

export default {
  components: {
    // ProductListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,
    BFormCheckbox,
    vSelect,
  },
  setup() {
    // Register module
    if (!store.hasModule("product-list"))
      store.registerModule("product-list", productStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule("product-list"))
        store.unregisterModule("product-list");
    });

    // const titleOptions = [
    //   { label: 'Mr.', value: 'Mr.' },
    //   { label: 'Mrs.', value: 'Mrs.' },
    //   { label: 'Ms.', value: 'Ms.' },
    // ]

    const statusOptions = [
      { label: "Pending", value: "Pending" },
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "Inactive" },
    ];

    const {
      fetchProducts,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductListTable,
      refetchData,

      // UI
      resolveStatusVariant,

      // Extra Filters
      productNameFilter,
      productCategoryFilter,
      statusFilter,
    } = useProductList();

    return {
      fetchProducts,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveStatusVariant,

      // titleOptions,
      statusOptions,

      // Extra Filters
      productNameFilter,
      productCategoryFilter,
      statusFilter,
    };
  },
  methods: {
    handlerDeleteProduct(id) {
      store.dispatch("product-list/deleteProduct", { id }).then((response) => {
        if (response.status === 200) {
          this.fetchProducts();
          this.refetchData();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
