import axios, { baseURL } from '@axios'
import { paginateArray, sortCompare } from '@/common'
import { getUserData } from "@/auth/utils";

export default {
  namespaced: true,
  state: {
   
  },
  getters: {},
  mutations: {},
  actions: {

    // New
    fetchProductList(ctx, queryParams) {
      const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'id',
        sortDesc = false,
      } = queryParams
      const payload = {
        key: q,
        dateFrom: null,
        dateTo: null,
        status: null
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Product/GetByKey`,payload)
          .then(response => {
            resolve({
              data: paginateArray(response.data.data, perPage, page),
              total: response.data.data.length,
            })
          })
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Product/GetById`, { 
            params: {
              ProductId: id
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProduct(ctx, { id }) {
    
      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Product/Delete?ProductId=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProduct(ctx, product) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Product/AddOrUpdate`, product, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // OLD
   
    uploadFile(ctx, formData) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Upload/Upload`, formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userData.token}`,
              },
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadFileMultiple(ctx, formData) {
      const userData = getUserData()

      return new Promise((resolve, reject) => {
        axios
          .post(`${baseURL}/api/Upload/MultiUpload/multiple`, formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userData.token}`,
              },
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
     getProductCategory() {
      const userData = getUserData()
 
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Category/GetByKey`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getProductExchange() {
      const userData = getUserData()
 
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Product/GetProductExchange`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getProductBrand() {
      const userData = getUserData()
 
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Product/GetProductBrand`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProductModel(ctx, { brand }) {
      const userData = getUserData()
 
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Product/GetProductModel?brand=${brand}`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProductYear(ctx, { brand, model }) {
      const userData = getUserData()
 
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Product/GetProductYear?brand=${brand}&model=${model}`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProductSubModel(ctx, { brand, model, year }) {
      const userData = getUserData()
 
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Product/GetProductSubModel?brand=${brand}&model=${model}&year=${year}`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getRoleAll() {
      const userData = getUserData()
    
      return new Promise((resolve, reject) => {
        axios
          .get(`${baseURL}/api/Role/GetByKey`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
